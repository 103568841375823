import React, { CSSProperties, Suspense } from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import S from "./Icon.module.scss";

export enum DA_IconNames {
    Add = "Add",
    AddChecklist = "Add checklist",
    AddComment = "Add comment",
    ArrowRight = "Add right",
    Barcode = "Barcode",
    Bell = "Bell",
    Calendar = "Calendar",
    CalendarCheck = "Calendar check",
    Cart = "Cart",
    CartWeighted = "CartWeighted",
    Chart = "Chart",
    Checkmark = "Checkmark",
    CheckmarkThin = "Checkmark thin",
    CheckListBoard = "Checklist board",
    ChevronLeft = "Chevron left",
    ChevronRight = "Chevron right",
    ChevronSmallDown = "Chevron small down",
    ChevronSmallLeft = "Chevron small left",
    ChevronSmallUp = "Chevron small up",
    Clock = "Clock",
    Close = "Close",
    CogwheelStroke = "Cogwheel stroke",
    Construction = "Construction",
    CogWheel = "Cog wheel",
    Cookie = "Cookie",
    CustomerService = "Customer service",
    Dankort = "Dankort",
    Document = "Document",
    DocumentOutline = "DocumentOutline",
    DankortWrapped = "DankortWrapped",
    Delivery = "Delivery",
    DeliveryTrolley = "Delivery trolley",
    DeliveryTruck = "Delivery truck",
    DollyStroke = "Dolly stroke",
    EmailEnvelope = "Email envelope",
    ExclamationMark = "Exclamation mark",
    Expand = "Expand",
    Ellipsis = "Ellipsis",
    EconomyPlaceholder = "Economy placeholder",
    ExternalLink = "External link",
    Facebook = "Facebook",
    FarmInTime = "Farm in time",
    FilterTool = "Filter tool",
    FormCheck = "Form check",
    FormCheckWeighted = "Form check weighted",
    FormSubmit = "Form submit",
    Globe = "Globe",
    Handshake = "Handshake",
    Harvest = "Harvest",
    HarvestMachine = "Harvest machine",
    HarvestStraw = "Harvest straw",
    Harvester = "Harvester",
    HeartOutline = "Heart outline",
    HeartSolid = "Heart solid",
    Info = "Info",
    Instagram = "Instagram",
    Invoice = "Invoice",
    Leaf = "Leaf",
    Letter = "Letter",
    LetterOutlined = "Letter outlined",
    LinkedIn = "LinkedIn",
    LocationPin = "Location pin",
    MagnifyingGlass = "Magnifying glass",
    Man = "Man",
    MasterCard = "Mastercard",
    MasterCardWrapped = "MastercardWrapped",
    MenuBurger = "Menu burger",
    MenuDots = "Menu dots",
    Minus = "Minus",
    Mobile = "Mobile",
    MobilePay = "MobilePay",
    MobilePayWrapped = "MobilePayWrapped",
    Pdf = "Pdf",
    PdfDocument = "PDF document",
    Pen = "Pen",
    Pencil = "Pencil",
    Phone = "Phone",
    PhoneOutline = "PhoneOutline",
    Pin = "Pin",
    PlantHand = "PlantHand",
    Play = "Play",
    Profile = "Profile",
    Question = "Question",
    Quote = "Quote",
    Remove = "Remove",
    Rosette = "Rosette",
    RotateDevice = "Rotate device",
    ScrollHorizontal = "Scroll horizontal",
    ScrollLeftRight = "Scroll left right",
    SearchOutline = "Search outline",
    SignOut = "Sign out",
    SmartPhone = "Smartphone",
    Star = "Star",
    StarSolid = "StarSolid",
    StatisticKvantum = "Statistic kvantum",
    StatisticBuy = "Statistic buy",
    StatisticSale = "Statistic sale",
    StatisticsAmount = "Statistics amount",
    ShoppingBasket = "Shopping basket",
    Support = "Support",
    Text = "Text",
    Tractor = "Tractor",
    Trash = "Trash",
    TriangleSmallDown = "Triangle small down",
    Truck = "Truck",
    TruckOutline = "Truck outline",
    Twitter = "Twitter",
    UserCrown = "User crown",
    UserLoggedIn = "User logged in",
    UserLoggedInWeighted = "User logged in weighted",
    UserLoggedOut = "User logged out",
    UserLoggedOutWeighted = "User logged out weighted",
    Van = "Van",
    Visa = "Visa",
    VisaWrapped = "VisaWrapped",
    WarningSign = "Warning sign",
    YouTube = "YouTube",
    Zoom = "Zoom",
    ZoomWeighted = "Zoom weighted",
    X = "X",
}

export interface DA_IconProps {
    name: DA_IconNames;
    className?: string;
    style?: CSSProperties;
    useSvgDimensions?: boolean;
}

const ReturnIcon = React.lazy(() => import("./ActualIcon.component"));

export const DA_Icon = ({ name, className, style, useSvgDimensions }: DA_IconProps) => {
    return (
        <span
            className={cn(S.wrapper, useSvgDimensions || S.fullSize, className)}
            style={style}
            role="none"
        >
            <Suspense>
                <ReturnIcon name={name} />
            </Suspense>
        </span>
    );
};
