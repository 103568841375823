import React, { useMemo } from "react";
import { DA_Button } from "../../atoms/Button/Button.component";
import { DA_Icon, DA_IconNames } from "../../atoms/Icon/Icon.component";
import { DA_Spinner } from "../../atoms/Spinner/Spinner.component";
import { DA_Text } from "../../atoms/Text/Text.component";
import { DA_Title } from "../../atoms/Title/Title.component";
import { useTranslations } from "../../../contexts/translations/translations.context";
import { cn } from "../../../helpers/classNames.helper";
import { DA_EmptyStateProps } from "./EmptyState.props";
import S from "./EmptyState.module.scss";

export enum DA_EmptyStateTypes {
    Loading = "Loading",
    Error = "Error",
    NoContent = "NoContent",
    NoResults = "NoResults",
    FormSubmit = "FormSubmit",
    NoCartItems = "NoCardItems",
    NoRights = "NoRights",
    NoFavorites = "NoFavorites",
}

export const DA_EmptyState = (props: DA_EmptyStateProps) => {
    const { getDictionaryItem } = useTranslations();

    const title = useMemo(() => {
        // Custom title
        if (props.doNotShowTitle) {
            return null;
        }

        // Custom title
        if (props.title) {
            return props.title;
        }
        switch (props.type) {
            // No cart items
            case DA_EmptyStateTypes.NoCartItems:
                return <DA_Icon name={DA_IconNames.Man} className={S.icon} />;
            // Loading
            case DA_EmptyStateTypes.Loading:
                return getDictionaryItem("loading");
            // No Content
            case DA_EmptyStateTypes.NoContent:
                return getDictionaryItem("noContent");
            // No Results
            case DA_EmptyStateTypes.NoResults:
                return getDictionaryItem("noResults");
            // Error
            case DA_EmptyStateTypes.Error:
                return getDictionaryItem("error");
            // Form Submit
            case DA_EmptyStateTypes.FormSubmit:
                return getDictionaryItem("formSubmitted");
            case DA_EmptyStateTypes.NoRights:
                return getDictionaryItem("noRights");
            // No Favorites
            case DA_EmptyStateTypes.NoFavorites:
                return getDictionaryItem("NoFavorites");
            // Fallback
            default:
                return "";
        }
    }, [props.type, props.title, getDictionaryItem, props.doNotShowTitle]);

    const icon = useMemo(() => {
        // Custom icon
        if (props.icon) {
            return <DA_Icon name={props.icon} className={S.icon} />;
        }
        switch (props.type) {
            // No cart items
            case DA_EmptyStateTypes.NoCartItems:
                return <DA_Icon name={DA_IconNames.Man} className={S.icon} />;
            // Loading
            case DA_EmptyStateTypes.Loading:
                return <DA_Spinner />;
            // No Content
            case DA_EmptyStateTypes.NoContent:
                return <DA_Icon name={DA_IconNames.Man} className={S.icon} />;
            // No Results
            case DA_EmptyStateTypes.NoResults:
                return <DA_Icon name={DA_IconNames.ExclamationMark} className={S.icon} />;
            // No Favorites
            case DA_EmptyStateTypes.NoFavorites:
                return <DA_Icon name={DA_IconNames.HeartOutline} className={S.icon} />;
            // Error
            case DA_EmptyStateTypes.Error:
                return <DA_Icon name={DA_IconNames.MagnifyingGlass} className={S.icon} />;
            // Form Submit
            case DA_EmptyStateTypes.FormSubmit:
                return <DA_Icon name={DA_IconNames.FormSubmit} className={S.icon} />;
            case DA_EmptyStateTypes.NoRights:
                return <DA_Icon name={DA_IconNames.ExclamationMark} className={S.icon} />;
            // Fallback
            default:
                return <DA_Icon name={DA_IconNames.Man} className={S.icon} />;
        }
    }, [props.type, props.icon]);

    return (
        <div className={cn(S.wrapper, props.fadeIn && S.fadeIn, props.iconOnly && S.iconOnly)}>
            {/** Icon */}
            {icon ? <div className={S.iconWrapper}>{icon}</div> : null}

            {/** Title */}
            <div className={S.emptyTitle}>
                <DA_Title h3 noMargin>
                    {title}
                </DA_Title>
            </div>

            {/** Text: String */}
            {typeof props.text === "string" && (
                <div className={S.text}>
                    <DA_Text smallText={props.smallText} noPadding html={props.text} />
                </div>
            )}

            {/** Text: ReactNode */}
            {React.isValidElement(props.text) && (
                <div className={S.text}>
                    <DA_Text smallText={props.smallText} noPadding>
                        {props.text}
                    </DA_Text>
                </div>
            )}

            {/** Button */}
            {props.cta ? (
                <div className={S.cta}>
                    <DA_Button title={props.cta.text} onClick={props.cta.onClick} />
                </div>
            ) : null}
        </div>
    );
};
