import React, { useCallback, useEffect, useState } from "react";
import {
    getBearerToken,
    getBearerTokenB2B,
} from "@danishagro/shared/src/helpers/getBearerToken.helper";
import { setCustomerNumberCookie } from "@danishagro/shared/src/helpers/setCustomerNumberCookie.helper";
import { getCookie, DetectInputMethod } from "@baggie/core";
import { DA_GlobalVariables } from "@danishagro/shared/src/interfaces/sharedGlobalVariables.interface";
import { useNavigate } from "react-router-dom";
import { CustomError } from "@danishagro/shared/src/utils/CustomError";
import { isSSR as isSSRHelper } from "../helpers/isSSR.helper";
import { DA_UserInformation } from "../interfaces/userInformation.interface";
import { DA_SiteVariant } from "../interfaces/siteVariant.interface";
import { SITE_VARIANTS } from "../constants/siteVariants.constants";
import { isSafari } from "../helpers/isSafari.helper";
import { useToastMessage } from "../hooks/useToastMessage.hook";
import { DA_BasicLink } from "../components/atoms/BasicLink/BasicLink.component";
import { getUrl } from "../helpers/getUrl.helper";

type SiteName = "B2B" | "MYFARM" | "CMS";

interface AppDataHook extends DA_GlobalVariables {
    acceptedPolicy: string;
    changeCustomer: (customerNumber: string, forceNew?: boolean) => Promise<void>;
    currentSite: SiteName;
    customerNumber: string;
    cvrNumber: string;
    fetchWithAuth: (url: RequestInfo, options?: RequestInit) => Promise<Response>;
    fetchWithAuthB2b: (
        url: RequestInfo,
        customerNumber: string,
        options?: RequestInit
    ) => Promise<Response>;
    isAppFunctional: boolean;
    isAppReady: boolean;
    isSSR: boolean;
    showError: (message: string) => void;
    showLoginLink: boolean;
    showPrices: boolean;
    showOnlyUniversalMenu: boolean;
    isFarmInTimeEnabled: boolean;
    isCheckoutDeliveryEnabled: boolean;
    isCheckoutDeliveryDatepickerEnabled: boolean;
    isCheckoutAdditionalInformationEnabled: boolean;
    isCheckoutAdditionalInformationPhoneNumberEnabled: boolean;
    deliveryOptionDeliveryModeCode: string;
    deliveryOptionPickupModeCode: string;
    allowContractDeductionsOnFutureContracts: boolean;
    siteVariant: DA_SiteVariant;
    updateContentObj: (key: string, value: Record<string, string> | undefined) => void;
    updateDictionaryObj: (key: string, value: Record<string, string> | undefined) => void;
    updateGlobalVariables: () => Promise<unknown>;
    updateName: (data: Pick<DA_UserInformation, "firstName" | "lastName">) => void;
    displayFavoritesList: boolean;
}

const AppDataContext = React.createContext<AppDataHook>({} as AppDataHook);

type Props = {
    children: React.ReactNode;
    currentSite: SiteName;
    getGlobalVariables: (
        customerNumber?: string,
        forceNew?: boolean
    ) => Promise<DA_GlobalVariables>;
};

let setViewportThrottle: number;

const setViewport = (): void => {
    window.clearTimeout(setViewportThrottle);
    setViewportThrottle = window.setTimeout(() => {
        if (
            !document.activeElement ||
            (document.activeElement.tagName !== "INPUT" &&
                document.activeElement.tagName !== "TEXTAREA")
        ) {
            const viewHeight = window.innerHeight;
            let viewport = document.querySelector("meta[name=viewport]");
            if (!viewport) {
                viewport = document.createElement("meta");
                viewport.setAttribute("name", "viewport");
            }
            viewport.setAttribute(
                "content",
                `height=${viewHeight}, width=device-width, initial-scale=1.0, viewport-fit=cover`
            );
        }
    }, 300);
};

const onResizeVisualViewport = (): void => {
    document.documentElement.style.setProperty(
        "--keyboard-height",
        `${window.innerHeight - window.visualViewport.height}px`
    );
};

export const AppDataProvider = ({
    currentSite,
    getGlobalVariables,
    children,
}: Props): JSX.Element => {
    const [isAppReady, setIsAppReady] = useState(false);
    const [isAppFunctional, setIsAppFunctional] = useState(false);
    const [isImpersonator, setIsImpersonator] = useState(false);
    const [isSSR, setIsSSR] = useState(true);
    const [customerNumber, setCustomerNumber] = useState("");
    const [cvrNumber, setCvrNumber] = useState<string>();
    const [acceptedPolicy, setAcceptedPolicy] = useState("UNKNOWN");
    const [globalVariables, setGlobalVariables] = useState<DA_GlobalVariables>();
    const [showLoginLink, setShowLoginLink] = useState(false);
    const [showPrices, setShowPrices] = useState(true);

    const [allowContractDeductionsOnFutureContracts, setAllowContractDeductionsOnFutureContracts] = useState(globalThis.allowContractDeductionsOnFutureContracts ?? false);
    const [showOnlyUniversalMenu, setShowOnlyUniversalMenu] = useState(globalThis.showOnlyUmbracoUniversalMenu ?? false);
    const [isCheckoutDeliveryEnabled, setIsCheckoutDeliveryEnabled] = useState(globalThis.isCheckoutDeliveryEnabled ?? false);
    const [isCheckoutDeliveryDatepickerEnabled, setisCheckoutDeliveryDatepickerEnabled] = useState(globalThis.isCheckoutDeliveryDatepickerEnabled ?? false);
    const [isCheckoutAdditionalInformationEnabled, setisCheckoutAdditionalInformationEnabled] = useState(globalThis.isCheckoutAdditionalInformationEnabled ?? false);
    const [isCheckoutAdditionalInformationPhoneNumberEnabled, setisCheckoutAdditionalInformationPhoneNumberEnabled] = useState(globalThis.isCheckoutAdditionalInformationPhoneNumberEnabled ?? false);
    const [deliveryOptionDeliveryModeCode, setDeliveryOptionDeliveryModeCode] = useState(globalThis.deliveryOptionDeliveryModeCode ?? "70");
    const [deliveryOptionPickupModeCode, setDeliveryOptionPickupModeCode] = useState(globalThis.deliveryOptionPickupModeCode ?? "40");
    const [isFarmInTimeEnabled, setIsFarmInTimeEnabled] = useState(globalThis.isFarmInTimeEnabled ?? true);
    const [displayFavoritesList, setDisplayFavoritesList] = useState(globalThis.showFavoritesList ?? false);

    const [siteVariant, setSiteVariant] = useState<DA_SiteVariant>(
        SITE_VARIANTS.find(({ alias }) => alias === globalThis.siteVariant) || SITE_VARIANTS[0]
    );
    const navigate = useNavigate();
    const toastMessage = useToastMessage();

    const showError = useCallback(
        (message: string) => {
            console.error(message);
            toastMessage({
                type: "error",
                title: <strong>Noget gik galt</strong>,
                message: (
                    <>
                        Du kan evt.{" "}
                        <DA_BasicLink href={getUrl("contact")}>kontakte kundeservice</DA_BasicLink>
                    </>
                ),
            });
        },
        [toastMessage]
    );

    const updateDictionaryObj = useCallback(
        (key: string, value: Record<string, string> | undefined) => {
            if (value) {
                setGlobalVariables({
                    ...globalVariables,
                    dictionaryObj: {
                        ...globalVariables.dictionaryObj,
                        translations: {
                            ...globalVariables.dictionaryObj.translations,
                            [key]: value[globalVariables.currentCulture],
                        },
                    },
                });
                globalThis.dictionaryObj.translations = {
                    ...globalThis.dictionaryObj.translations,
                    [key]: value[globalVariables.currentCulture],
                };
            } else {
                const newTranslations = {
                    ...globalVariables.dictionaryObj.translations,
                };
                delete newTranslations[key];

                setGlobalVariables({
                    ...globalVariables,
                    dictionaryObj: {
                        ...globalVariables.dictionaryObj,
                        translations: {
                            ...newTranslations,
                        },
                    },
                });
                globalThis.dictionaryObj.translations = {
                    ...newTranslations,
                };
            }
        },
        [globalVariables]
    );

    const updateContentObj = useCallback(
        (key: string, value: Record<string, string> | undefined) => {
            if (value) {
                setGlobalVariables({
                    ...globalVariables,
                    contentObj: {
                        ...globalVariables.contentObj,
                        translations: {
                            ...globalVariables.contentObj.translations,
                            [key]: value[globalVariables.currentCulture],
                        },
                    },
                });
                globalThis.contentObj.translations = {
                    ...globalThis.contentObj.translations,
                    [key]: value[globalVariables.currentCulture],
                };
            } else {
                const newTranslations = {
                    ...globalVariables.contentObj.translations,
                };
                delete newTranslations[key];

                setGlobalVariables({
                    ...globalVariables,
                    contentObj: {
                        ...globalVariables.contentObj,
                        translations: {
                            ...newTranslations,
                        },
                    },
                });
                globalThis.contentObj.translations = {
                    ...newTranslations,
                };
            }
        },
        [globalVariables]
    );

    const acceptedPrivacyPolicy = useCallback(
        (customerNumber: string) => {
            globalVariables?.cvrAndCustomerNumbers?.accounts?.map((account) => {
                const currentCustomerObject = account.customers.find(
                    (x) => x.number === customerNumber
                );

                if (currentCustomerObject?.hasAcceptedPrivacyPolicy) {
                    setAcceptedPolicy("ACCEPTED");
                } else if (currentCustomerObject) {
                    setAcceptedPolicy("UNDECIDED");
                }
            });
        },
        [globalVariables]
    );

    useEffect(() => {
        acceptedPrivacyPolicy(customerNumber);
    }, [acceptedPrivacyPolicy, customerNumber]);

    const fetchWithAuth = useCallback(
        (url: RequestInfo, options?: RequestInit) =>
            getBearerToken().then((accessToken) => {
                globalThis.accessToken = accessToken;

                return fetch(url, {
                    ...options,
                    headers: {
                        Authorization: "Bearer " + accessToken,
                        ...(options?.headers || {}),
                    },
                }).then((response) => {
                    if (!response.ok) {
                        return response.text().then((text) => {
                            let errorData = null;
                            let errorMessage = text;

                            try {
                                errorData = JSON.parse(text);
                                if (errorData && errorData.message) {
                                    errorMessage = errorData.message;
                                }
                            } catch (e) {
                                // Text is not JSON
                            }

                            // Display the error message
                            showError(errorMessage);

                            // Create a custom error object
                            const error = new CustomError(errorMessage, response, errorData);
                            throw error;
                        });
                    }
                    return response;
                });
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [showError]
    );

    const fetchWithAuthB2b = useCallback(
        (url: RequestInfo, customerNumber: string, options?: RequestInit) =>
            getBearerTokenB2B(customerNumber).then((dwToken) => {
                // Update global DW token and expiration time
                globalThis.dwToken = dwToken;

                return fetch(url, {
                    ...options,
                    headers: {
                        Authorization: "Bearer " + dwToken,
                        ...(options?.headers || {}),
                    },
                }).then((response) => {
                    if (response?.ok === false) {
                        response.text().then((message) => showError(message));
                    }
                    return response;
                });
            }),
        [showError]
    );

    const changeCustomer = useCallback(
        async (newCustomerNumber: string, forceNew = false) => {
            const number =
                newCustomerNumber ||
                (location.hostname === "localhost"
                    ? getCookie("localhost-last-customer", true)
                    : "") ||
                "";

            try {
                const variables = await getGlobalVariables(number, forceNew);

                if (newCustomerNumber) globalThis.dwToken = undefined;

                setGlobalVariables({
                    ...variables,
                    cvrAndCustomerNumbers: {
                        ...variables.cvrAndCustomerNumbers,
                        defaultCustomerNumber:
                            number || variables.cvrAndCustomerNumbers?.defaultCustomerNumber || "",
                    },
                });

                setAcceptedPolicy("UNKNOWN");
                if (number) {
                    setCustomerNumber(number);
                } else if (variables.cvrAndCustomerNumbers?.defaultCustomerNumber) {
                    setCustomerNumber(variables.cvrAndCustomerNumbers.defaultCustomerNumber);
                }
                setIsAppReady(true);
                setIsImpersonator(globalThis.isImpersonator);
                setIsAppFunctional(
                    typeof globalThis.dictionaryObj?.translations !== "undefined" &&
                        Array.isArray(globalThis.navigationObj) &&
                        globalThis.navigationObj.length > 0
                );

                if (newCustomerNumber) {
                    setCustomerNumberCookie(globalThis.accessToken, newCustomerNumber);
                }
            } catch (err) {
                console.log(err);
                if (location.hostname === "localhost") {
                    setShowLoginLink(true);
                } else {
                    globalThis.showServerError = true;
                    setIsAppReady(true);
                }
            }
        },
        [getGlobalVariables]
    );

    const updateName: AppDataHook["updateName"] = useCallback(
        ({ firstName, lastName }) => {
            if (currentSite !== "MYFARM") throw "You can only update your name on MyFarm";
            setGlobalVariables((current) => ({
                ...current,
                cvrAndCustomerNumbers: { ...current.cvrAndCustomerNumbers, firstName, lastName },
            }));
        },
        [currentSite]
    );

    const updateGlobalVariables = useCallback(
        () =>
            new Promise((resolve, reject) =>
                getGlobalVariables(customerNumber, true)
                    .then((variables) => {
                        setGlobalVariables(variables);
                        fetch("/clearUserCache")
                            .then(() => resolve(null))
                            .catch((err) => reject(err));
                    })
                    .catch((err) => reject(err))
            ),
        [customerNumber, getGlobalVariables]
    );

    useEffect(() => {
        if (
            customerNumber &&
            globalVariables?.cvrAndCustomerNumbers?.accounts &&
            globalVariables.cvrAndCustomerNumbers.accounts.length
        ) {
            const customer = globalVariables.cvrAndCustomerNumbers.accounts.find((account) =>
                account.customers.find((customer) => customer.number === customerNumber)
            );

            if (customer?.cvr) {
                setCvrNumber(customer.cvr);
            }
        }
    }, [customerNumber, globalVariables]);

    useEffect(() => {
        setShowPrices(
            globalThis.isPriceServiceEnabled !== undefined ? globalThis.isPriceServiceEnabled : true
        );
    }, []);

    useEffect(() => {
        const themeClass = document.body.className
            .replace(/\s+/, "")
            .split(" ")
            .find((className) => className.startsWith("theme--"));

        if (themeClass) {
            document.body.classList.replace(themeClass, `theme--${siteVariant.alias}`);
        } else {
            document.body.classList.add(`theme--${siteVariant.alias}`);
        }
    }, [siteVariant]);

    useEffect(() => {
        const bodyClick = (event: MouseEvent) => {
            const target = event.target as HTMLAnchorElement;
            if (target.hasAttribute("data-internal-link-from-string")) {
                event.preventDefault();
                navigate(target.getAttribute("href") || "");
            }
        };
        document.body.addEventListener("click", bodyClick);
        return () => document.body.removeEventListener("click", bodyClick);
    }, [navigate]);

    useEffect(() => {
        setViewport();
        const inputMethod = new DetectInputMethod({
            continuousDetection: true,
        });
        window.addEventListener("resize", setViewport);

        if (window.visualViewport) {
            window.visualViewport.addEventListener("resize", onResizeVisualViewport);
        }

        if (isSafari()) {
            document.documentElement.classList.add("is-safari");
        }

        return () => {
            inputMethod.unbind();
            window.removeEventListener("resize", setViewport);

            if (window.visualViewport) {
                window.visualViewport.removeEventListener("resize", onResizeVisualViewport);
            }
        };
    }, []);

    useEffect(() => {
        // This should really only ever be triggered in Storybook
        const observer = new MutationObserver((mutations: MutationRecord[]) => {
            mutations.forEach((mutation) => {
                if (mutation.type === "attributes" && mutation.attributeName === "class") {
                    const classes = document.body.className.replace(/\s+/g, " ").split(" ");
                    const themeClass = classes.find((className) => className.startsWith("theme--"));
                    const newSiteVariantAlias = themeClass?.replace("theme--", "");

                    if (newSiteVariantAlias !== siteVariant.alias) {
                        const newSiteVariant = SITE_VARIANTS.filter(
                            ({ alias }) => alias === newSiteVariantAlias
                        );

                        if (newSiteVariant.length) {
                            setSiteVariant(newSiteVariant[0]);
                        }
                    }
                }
            });
        });

        observer.observe(document.body, { attributes: true });

        return () => observer.disconnect();
    }, [siteVariant]);

    // useEffects don't run on servers - let's take advantage of that
    useEffect(() => {
        if (!isSSRHelper) {
            setIsSSR(false);
        }
    }, []);

    useEffect(() => {
        changeCustomer("", false);
    }, [changeCustomer]);

    return (
        <AppDataContext.Provider
            value={{
                ...globalVariables,
                acceptedPolicy,
                changeCustomer,
                currentSite,
                customerNumber,
                cvrNumber,
                fetchWithAuth,
                fetchWithAuthB2b,
                isAppFunctional,
                isAppReady,
                isImpersonator,
                isSSR,
                showError,
                showLoginLink,
                showPrices,
                showOnlyUniversalMenu,
                isFarmInTimeEnabled,
                isCheckoutDeliveryEnabled,
                isCheckoutDeliveryDatepickerEnabled,
                isCheckoutAdditionalInformationEnabled,
                isCheckoutAdditionalInformationPhoneNumberEnabled,
                deliveryOptionDeliveryModeCode,
                deliveryOptionPickupModeCode,
                allowContractDeductionsOnFutureContracts,
                siteVariant,
                updateContentObj,
                updateDictionaryObj,
                updateGlobalVariables,
                updateName,
                displayFavoritesList,
            }}
        >
            {children}
        </AppDataContext.Provider>
    );
};

export const useAppData = (): AppDataHook => React.useContext(AppDataContext);
